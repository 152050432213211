:root {
  --style-blue: #1A91F0;
  --style-blue-mid: #1170CD;
  --style-blue-cyan:cyan;
  --style-skye-blue:skyblue;
  --style-blue-dark: rgb(3, 25, 71);
  --style-blue-footer:hsl(210,25%,8%);
  --style-gold:#db8708;
  --style-dark-bg:  #16191e;
  --style-orange:rgb(255, 153, 0);
  --style-yellow:yellow;
  --style-lemon:rgb(13, 223, 13);
  --style-white: #fff;
  --style-bright: #f5f6fa;
  --style-dark: #1e2532;
  --style-black: #000;
  --style-grey: #656e83;
  --style-light-grey:#16191e;
  --style-green: #084C41;
  --style-dark-bg:hsl(210,55%,8%);
  --style-success:rgb(33, 92, 219);
  --style-error:rgb(219, 33, 33);
  --style-light-dark-bg:hsl(210,55%,8%);
  --navbar-color:rgb(85, 79, 79);
  --font-poppins: 'Poppins', sans-serif;
  --font-manrope: 'Manrope', sans-serif;
  --style-light-gray:rgb(218, 204, 204);
  --transition: all 300ms ease-in-out;
  --header-height:600px;
  --navbar-height:70px;
  --divider-height:130px;
  --font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
* {
  padding: 0;
  margin: 0;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  box-sizing: border-box;
}
body {
  /* font-size: 1.6rem; */
}
p{
  /* opacity: 0.8; */
}
li{
  list-style: none;
}
a{
  text-decoration: none;
}
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
}
