nav {
    width: 100%;
    height: var(--navbar-height);
    display: flex;
    padding: 10px 30px;
    align-items: center;
    justify-content: space-between;
    background: transparent;
}
.toggle-menu{
    color: var(--style-blue-dark);
    outline: none;
    right: 20px;
    top:20px;
    position: absolute;
    font-size: 24px;
    display: none;
}
.app-logo{
width: 60px;
height: 60px;
border-radius: 50%;
cursor: pointer;
}
.list {
    list-style-type: none;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 50px;
}
.list:nth-last-child(n+2) {color:var(--style-bright) !important;}
/* li:nth-child(n+2) {} selects from the second onwards */
/* li:nth-child(n-2) {} /* selects everything */
/* li:nth-child(-n+2) {} selects first two only  */
/* li:nth-child(-n-2) {} selects nothing  */
.items {
    margin-right: 20px;
    font-weight: lighter;
    font-size: 14.5px;
    color: var(--style-grey);
    cursor: pointer;
    font-family: var(--font-family);
}
.logout{
    color: var(--style-bright) !important;
    font-weight: bold !important;
    background-color: var(--style-blue-dark) !important;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
}
.items:hover{
    border-bottom: 2px solid var(--style-blue-cyan);
    transition: var(--transition);
    color: var(--style-blue-dark);
}

.btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: var(--style-blue-dark);
    font-size: 18px;
}

/* Mobile Device */

@media screen and (max-width: 500px){
    nav {
        width: 100%;
        height: var(--navbar-height);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .list {
        flex-direction: column;
        background: var(--style-blue-dark);
        border: thin solid var(--style-blue-cyan);
        border-radius: 5px;
        height: auto;
        padding: 0 20px;
        top: var(--navbar-height);
        width: 70%;
        left: 0;
        justify-content: flex-start;
        z-index: 400;
    }
    .items:nth-child(1){
        border-top: 1px solid rgba(255, 255, 255, 0.555);
        margin-top: 50px;
    }
    .items {
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.555);
        text-align: center;
        color: var(--style-blue-cyan) !important;
        margin-right: 0px;
        padding: 20px 0;
    }
    .btn {
        display: block;
        color: var(--style-bright);
        right: 0;
        border: none;
        top: 0;
        background: transparent;
        position: absolute;
    }
    .toggle-menu{
        display: block;
    }
}