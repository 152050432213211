#app-main{
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background: #16191e;
    color: hsl(210,8%,55%);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
}

#app-main p{
    color: darken(#e4e4e4,20%);
}
#app-left-widget{
    padding-left: 10px;
}

.app-left-widget-min-ul{
max-width: fit-content;
background-color: inherit;
padding: 20px 10px;
transition: all 0.5s ease;
overflow: hidden;

}
#app-left-widget-max-ul{
    max-width: 200px;
    background-color: inherit;
    padding: 20px 10px;
    transition: all 0.5s ease;
    overflow: hidden;
}
#app-left-widget #app-title-stock, #app-title-stock{
color: var(--style-grey);
font-size: 16px;
padding: 0 5px;
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
}
#app-left-widget #app-title-manager, #app-title-manager{
color: gray;
font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
}

#app-left-widget #app-left-navigation #app-left-widget-min-ul li{
    display: flex;
    grid-gap: 1px;
    flex-direction: column;

}
#app-left-widget #app-left-navigation #app-left-widget-min-ul li #app-greater-than{
transform: rotateZ(90deg);
transition: all 2s ease;
color: gray;
}
#app-left-widget #app-left-navigation #app-left-widget-min-ul li #app-list-left #app-text{
    display: none;

}
#app-left-navigation{
    margin-bottom: 40px;
}

#app-left-navigation ul{
    padding: 0 10px;
    margin-top: 20px;
    height: 70vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
#app-left-navigation h5{
    text-align: center;
    padding-top: 10px;
    margin-left: 10px;
}

#app-left-navigation ul::-webkit-scrollbar{
    width: 10px;
    border-radius: 6px;
}
#app-left-navigation ul::-webkit-scrollbar-track{
    box-shadow: inset 0 0 10px rgb(0, 0, 0, 0.25);
}
#app-left-navigation ul::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background:#000;
}
#app-left-navigation ul::-webkit-scrollbar-track-piece{
    background-color: #333;
}

#app-left-navigation ul li{
padding-top: 30px;
display: flex;
justify-content: space-between;
gap: 30px;
align-items: center;
cursor: pointer;
transform: scale(1);
}
#app-left-navigation ul li:hover{
        color: var(--style-bright);
        transform: scale(1.1);
        transition: var(--transition);
}

#app-left-navigation ul li:active{
    transform: scale(1);
}

#app-left-navigation ul li #app-list-left{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
}
#app-icon{
    margin: 10px 0;
    padding: 10px;
    border-radius: 50%;
    background-color: #000;
    font-size: 40px;
}
#app-logout-icon{
    margin: 35px 0;
    padding: 10px;
    border-radius: 50%;
    background-color: #000;
    font-size: 40px;
}
#app-left-navigation ul li #app-list-left #app-text{
    text-align: left;
    font-size: 16px;
}
@media screen and (max-width:500px) {
    #app-left-navigation ul li #app-list-left #app-text{
        display: none;
    }
    #app-left-navigation ul li #app-list-left #app-icon{
        margin: 5px 0;
        padding: 5px;
        border-radius: 50%;
        background-color: #000;
       }
    #app-left-navigation ul li #app-list-left #app-logout-icon{
        margin: 5px 0;
        padding: 5px;
        border-radius: 50%;
        background-color: #000;
    }
    #app-left-navigation ul li{
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        }
}
#app-right-widget{
width: 100%;
height: 100vh;
background-color: #000;
}

#app-right-top-bar{
    height: 80px;
    width:100%;
    background: #16191e;
    color: hsl(210,8%,55%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 50px;
    padding-right: 20px;
}
@media screen and (max-width:500px) {
    #app-right-top-bar{
        position: relative;
        margin-top: 0;
        height: auto;
        color: hsl(210,8%,55%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column-reverse;
        gap: 20px;
        padding: 20px;
    }
    #app-right-top-bar #app-right{
        display: flex;
        gap: 20px;
        flex-direction: row;
        align-items: center;
    }
    #app-left-widget{
        position: relative;
    }
    #app-hamburger-icon{
        position: fixed;
        left: 10px;
        top: 15px;
        font-size: 24px;
    }
}

#app-right-top-bar #app-left{
    flex-basis: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
}
#app-right-top-bar #app-left input{
    flex-basis: 90%;
    background-color: #16191e;
    color: hsl(210,8%,55%);
    border: 1px solid gray;
    padding: 10px 5px;
    height: 40px;
    font-size: 20px;
    border-radius: 10px;
}
#app-right-top-bar #app-left input:focus{
outline:1px solid var(--bg-primary-color);
}
#app-right-top-bar #app-left #app-hamburger-icon{
cursor: pointer;
}
#app-right-top-bar #app-right{
    flex-basis: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
#app-right-top-bar #app-right #app-wrapper{
        position: relative;
    }
    #app-right-top-bar #app-right #app-wrapper #app-unread{
        position: absolute;
        top: -2px;
        right: -3px;
        background-color: red;
        min-width: fit-content;
        min-height: fit-content;
        display: none;
        border-radius: 50%;
    }
    #app-right-top-bar #app-right #app-wrapper #app-read{
        position: absolute;
        top: -2px;
        right: -3px;
        min-width: fit-content;
        min-height: fit-content;
        border-radius: 50%;
        display: none;
    }
    #app-right-top-bar #app-right #app-wrapper div p{
        position: relative;
        color: #fff;
        font-size: 10px;
        margin: auto;
        padding-top: 2px;
    }
#app-right-top-bar #app-right > div{
cursor: pointer;
}
#app-right-top-bar #app-right #app-top-right-icons{
    color: hsl(210,8%,55%);
    font-size: 24px;
}
#app-right-top-bar #app-right #app-add-icon{
    color: green;
    font-size: 24px;
}

#app-right-widget #app-right-body{
position: relative;
height: 84vh;
width: 100%;
overflow-y: auto;
display: flex;
align-items: center;
flex-direction: column;
gap: 20px;
margin-bottom: 10px;
}