.regular-user{
    color: gold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.premium{
    color:gold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.premium-crown{
    color: gold !important;
}
.make-regular{
    background-color: var(--bg-primary-color) !important;
    padding: 5px 100px;
}
.btn-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn-wrapper > *{
    margin: 0 3rem;
    background-color: red;
    color: #333;
}